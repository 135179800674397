import { Composition } from '../composition';
import { copyElementToImageFactory } from '../utils/2d';
import { mirrorHorizontal, mirrorVertical } from '../utils/mirror';
import {
  COMPOSITION_TO_PIXELS,
  ImageDrawnState,
  TRANSPARENT_COLOR,
} from './constants';
import { ImageState } from './types';

const CONTINENT_BORDER = [-1, 1, 3, 4];
// const REGION_BORDER = [10, 10, 3, 0];
const REGION_BORDER = [TRANSPARENT_COLOR, TRANSPARENT_COLOR, 1, -1];

export const REGION_BORDER_WIDTH = 2;

export const getContinentBorderRenderer = (
  composition: Composition,
  imageState: ImageState,
) => {
  const { regions, output } = composition;

  const regionContinents = regions.continents;
  const { w: outputWidth, h: outputHeight } = output;

  const {
    verticalBorderFacingLeft,
    verticalBorderFacingRight,
    horizontalBorderFacingTop,
    horizontalBorderFacingBottom,
    leftToTopJoinBorderConcave,
    leftToTopJoinBorderConvex,
    leftToBottomJoinBorderConcave,
    leftToBottomJoinBorderConvex,
    rightToBottomJoinBorderConcave,
    rightToBottomJoinBorderConvex,
    rightToTopJoinBorderConcave,
    rightToTopJoinBorderConvex,
  } = generateBorders(CONTINENT_BORDER);

  const copyBorderToImage = copyElementToImageFactory(
    imageState,
    COMPOSITION_TO_PIXELS,
  )({
    w: CONTINENT_BORDER.length,
    h: CONTINENT_BORDER.length,
  });

  return () => {
    for (let y = 0; y < outputHeight; ++y) {
      for (let x = 0; x < outputWidth; ++x) {
        const i = y * outputWidth + x;
        const regionContinent = regionContinents[i];
        if (regionContinent === 0) {
          continue;
        }
        // if (imageState.drawn[i] & ImageDrawnState.DrawnContinentBorder) {
        //   continue;
        // }
        // imageState.drawn[i] |= ImageDrawnState.DrawnContinentBorder;

        const isConnectedToTop =
          y === 0 || regionContinents[i - outputWidth] === regionContinent;
        const isConnectedToBottom =
          y === outputHeight - 1 ||
          regionContinents[i + outputWidth] === regionContinent;
        const isConnectedToLeft =
          x === 0 || regionContinents[i - 1] === regionContinent;
        const isConnectedToRight =
          x === outputWidth - 1 || regionContinents[i + 1] === regionContinent;

        const isTopLeftDifferent =
          y !== 0 &&
          x !== 0 &&
          regionContinents[i - outputWidth - 1] < regionContinent;
        const isTopRightDifferent =
          y !== 0 &&
          x !== outputWidth - 1 &&
          regionContinents[i - outputWidth + 1] < regionContinent;
        const isBottomLeftDifferent =
          y !== outputHeight - 1 &&
          x !== 0 &&
          regionContinents[i + outputWidth - 1] < regionContinent;
        const isBottomRightDifferent =
          y !== outputHeight - 1 &&
          x !== outputWidth - 1 &&
          regionContinents[i + outputWidth + 1] < regionContinent;

        const isLeftDifferent =
          x !== 0 && regionContinents[i - 1] < regionContinent;
        const isRightDifferent =
          x !== outputWidth - 1 && regionContinents[i + 1] < regionContinent;
        const isTopDifferent =
          y !== 0 && regionContinents[i - outputWidth] < regionContinent;
        const isBottomDifferent =
          y !== outputHeight - 1 &&
          regionContinents[i + outputWidth] < regionContinent;

        let drawnBorder: Int8Array | null = null;
        const c = { x, y };
        // handles edge cases where corners are needed for joining different continents
        if (isConnectedToLeft && isBottomLeftDifferent) {
          drawnBorder = leftToBottomJoinBorderConvex;
          copyBorderToImage(leftToBottomJoinBorderConvex, c);
        }
        if (isConnectedToRight && isBottomRightDifferent) {
          drawnBorder = rightToBottomJoinBorderConvex;
          copyBorderToImage(rightToBottomJoinBorderConvex, c);
        }
        if (isConnectedToLeft && isTopLeftDifferent) {
          drawnBorder = leftToTopJoinBorderConvex;
          copyBorderToImage(leftToTopJoinBorderConvex, c);
        }
        if (isConnectedToRight && isTopRightDifferent) {
          drawnBorder = rightToTopJoinBorderConvex;
          copyBorderToImage(rightToTopJoinBorderConvex, c);
        }
        if (isConnectedToTop && isTopLeftDifferent) {
          drawnBorder = leftToTopJoinBorderConvex;
          copyBorderToImage(leftToTopJoinBorderConvex, c);
        }
        if (isConnectedToTop && isTopRightDifferent) {
          drawnBorder = rightToTopJoinBorderConvex;
          copyBorderToImage(rightToTopJoinBorderConvex, c);
        }
        if (isConnectedToBottom && isBottomLeftDifferent) {
          drawnBorder = leftToBottomJoinBorderConvex;
          copyBorderToImage(leftToBottomJoinBorderConvex, c);
        }
        if (isConnectedToBottom && isBottomRightDifferent) {
          drawnBorder = rightToBottomJoinBorderConvex;
          copyBorderToImage(rightToBottomJoinBorderConvex, c);
        }

        if (isConnectedToBottom || isConnectedToTop) {
          if (isLeftDifferent) {
            drawnBorder = verticalBorderFacingRight;
            copyBorderToImage(verticalBorderFacingRight, c);
          } else if (isRightDifferent) {
            drawnBorder = verticalBorderFacingLeft;
            copyBorderToImage(verticalBorderFacingLeft, c);
          }
        }

        if (isConnectedToLeft || isConnectedToRight) {
          if (isTopDifferent) {
            drawnBorder = horizontalBorderFacingBottom;
            copyBorderToImage(horizontalBorderFacingBottom, c);
          } else if (isBottomDifferent) {
            drawnBorder = horizontalBorderFacingTop;
            copyBorderToImage(horizontalBorderFacingTop, c);
          }
        }

        if (isConnectedToBottom && isConnectedToLeft) {
          if (isTopRightDifferent && isTopDifferent && isRightDifferent) {
            drawnBorder = leftToBottomJoinBorderConcave;
            copyBorderToImage(leftToBottomJoinBorderConcave, c);
          }
          if (isBottomLeftDifferent) {
            drawnBorder = leftToBottomJoinBorderConvex;
            copyBorderToImage(leftToBottomJoinBorderConvex, c);
          }
        }

        if (isConnectedToBottom && isConnectedToRight) {
          if (isTopLeftDifferent && isTopDifferent && isLeftDifferent) {
            drawnBorder = rightToBottomJoinBorderConcave;
            copyBorderToImage(rightToBottomJoinBorderConcave, c);
          }
          if (isBottomRightDifferent) {
            drawnBorder = rightToBottomJoinBorderConvex;
            copyBorderToImage(rightToBottomJoinBorderConvex, c);
          }
        }

        if (isConnectedToTop && isConnectedToLeft) {
          if (isBottomRightDifferent && isBottomDifferent && isRightDifferent) {
            drawnBorder = leftToTopJoinBorderConcave;
            copyBorderToImage(leftToTopJoinBorderConcave, c);
          }
          if (isTopLeftDifferent) {
            drawnBorder = leftToTopJoinBorderConvex;
            copyBorderToImage(leftToTopJoinBorderConvex, c);
          }
        }

        if (isConnectedToTop && isConnectedToRight) {
          if (isBottomLeftDifferent && isBottomDifferent && isLeftDifferent) {
            drawnBorder = rightToTopJoinBorderConcave;
            copyBorderToImage(rightToTopJoinBorderConcave, c);
          }
          if (isTopRightDifferent) {
            drawnBorder = rightToTopJoinBorderConvex;
            copyBorderToImage(rightToTopJoinBorderConvex, c);
          }
        }

        if (!!drawnBorder) {
          for (let dy = 0; dy < COMPOSITION_TO_PIXELS; ++dy) {
            for (let dx = 0; dx < COMPOSITION_TO_PIXELS; ++dx) {
              const index =
                x * COMPOSITION_TO_PIXELS +
                dx +
                (y * COMPOSITION_TO_PIXELS + dy) * imageState.w;
              if (
                drawnBorder[dx + dy * COMPOSITION_TO_PIXELS] !==
                TRANSPARENT_COLOR
              ) {
                imageState.drawn[index] = ImageDrawnState.DrawnContinentBorder;
              }
            }
          }
        }
      }
    }
  };
};

export const getRegionBorderRenderer = (
  composition: Composition,
  imageState: ImageState,
) => {
  const regionMarkers = composition.regions.markers;
  const { w: outputWidth, h: outputHeight } = composition.output;
  const {
    verticalBorderFacingLeft,
    verticalBorderFacingRight,
    horizontalBorderFacingTop,
    horizontalBorderFacingBottom,
    leftToTopJoinBorderConcave,
    leftToTopJoinBorderConvex,
    leftToBottomJoinBorderConcave,
    leftToBottomJoinBorderConvex,
    rightToBottomJoinBorderConcave,
    rightToBottomJoinBorderConvex,
    rightToTopJoinBorderConcave,
    rightToTopJoinBorderConvex,
  } = generateBorders(REGION_BORDER);

  const copyBorderToImage = copyElementToImageFactory(
    imageState,
    COMPOSITION_TO_PIXELS,
  )({
    w: REGION_BORDER.length,
    h: REGION_BORDER.length,
  });

  return () => {
    for (let y = 0; y < composition.output.h; ++y) {
      for (let x = 0; x < composition.output.w; ++x) {
        const i = y * composition.output.w + x;
        const regionMarker = regionMarkers[i];
        if (regionMarker === 0) {
          continue;
        }
        const isConnectedToTop =
          y === 0 || regionMarkers[i - outputWidth] === regionMarker;
        const isConnectedToBottom =
          y === outputHeight - 1 ||
          regionMarkers[i + outputWidth] === regionMarker;
        const isConnectedToLeft =
          x === 0 || regionMarkers[i - 1] === regionMarker;
        const isConnectedToRight =
          x === outputWidth - 1 || regionMarkers[i + 1] === regionMarker;

        const isTopLeftDifferent =
          y !== 0 &&
          x !== 0 &&
          regionMarkers[i - outputWidth - 1] !== regionMarker;
        const isTopRightDifferent =
          y !== 0 &&
          x !== outputWidth - 1 &&
          regionMarkers[i - outputWidth + 1] !== regionMarker;
        const isBottomLeftDifferent =
          y !== outputHeight - 1 &&
          x !== 0 &&
          regionMarkers[i + outputWidth - 1] !== regionMarker;
        const isBottomRightDifferent =
          y !== outputHeight - 1 &&
          x !== outputWidth - 1 &&
          regionMarkers[i + outputWidth + 1] !== regionMarker;

        const isLeftDifferent =
          x !== 0 && regionMarkers[i - 1] !== regionMarker;
        const isRightDifferent =
          x !== outputWidth - 1 && regionMarkers[i + 1] !== regionMarker;
        const isTopDifferent =
          y !== 0 && regionMarkers[i - outputWidth] !== regionMarker;
        const isBottomDifferent =
          y !== outputHeight - 1 &&
          regionMarkers[i + outputWidth] !== regionMarker;

        let drawnBorder: Int8Array | null = null;
        // // handles edge cases where corners are needed for joining different continents
        // if (isConnectedToLeft && isBottomLeftDifferent) {
        //   isBorderDrawn = true;
        //   copyBorderToImage(leftToBottomJoinBorderConvex, c);
        // }
        // if (isConnectedToRight && isBottomRightDifferent) {
        //   isBorderDrawn = true;
        //   copyBorderToImage(rightToBottomJoinBorderConvex, c);
        // }
        // if (isConnectedToLeft && isTopLeftDifferent) {
        //   isBorderDrawn = true;
        //   copyBorderToImage(leftToTopJoinBorderConvex, c);
        // }
        // if (isConnectedToRight && isTopRightDifferent) {
        //   isBorderDrawn = true;
        //   copyBorderToImage(rightToTopJoinBorderConvex, c);
        // }
        // if (isConnectedToTop && isTopLeftDifferent) {
        //   isBorderDrawn = true;
        //   copyBorderToImage(leftToTopJoinBorderConvex, c);
        // }
        // if (isConnectedToTop && isTopRightDifferent) {
        //   isBorderDrawn = true;
        //   copyBorderToImage(rightToTopJoinBorderConvex, c);
        // }
        // if (isConnectedToBottom && isBottomLeftDifferent) {
        //   isBorderDrawn = true;
        //   copyBorderToImage(leftToBottomJoinBorderConvex, c);
        // }
        // if (isConnectedToBottom && isBottomRightDifferent) {
        //   isBorderDrawn = true;
        //   copyBorderToImage(rightToBottomJoinBorderConvex, c);
        // }

        const c = { x, y };
        if (isConnectedToBottom || isConnectedToTop) {
          if (isLeftDifferent) {
            drawnBorder = verticalBorderFacingRight;
            copyBorderToImage(verticalBorderFacingRight, c);
          } else if (isRightDifferent) {
            drawnBorder = verticalBorderFacingLeft;
            copyBorderToImage(verticalBorderFacingLeft, c);
          }
        }

        if (isConnectedToLeft || isConnectedToRight) {
          if (isTopDifferent) {
            drawnBorder = horizontalBorderFacingBottom;
            copyBorderToImage(horizontalBorderFacingBottom, c);
          } else if (isBottomDifferent) {
            drawnBorder = horizontalBorderFacingTop;
            copyBorderToImage(horizontalBorderFacingTop, c);
          }
        }

        if (isConnectedToBottom && isConnectedToLeft) {
          if (isTopRightDifferent && isTopDifferent && isRightDifferent) {
            drawnBorder = leftToBottomJoinBorderConcave;
            copyBorderToImage(leftToBottomJoinBorderConcave, c);
          }
          if (isBottomLeftDifferent) {
            drawnBorder = leftToBottomJoinBorderConvex;
            copyBorderToImage(leftToBottomJoinBorderConvex, c);
          }
        }

        if (isConnectedToBottom && isConnectedToRight) {
          if (isTopLeftDifferent && isTopDifferent && isLeftDifferent) {
            drawnBorder = rightToBottomJoinBorderConcave;
            copyBorderToImage(rightToBottomJoinBorderConcave, c);
          }
          if (isBottomRightDifferent) {
            drawnBorder = rightToBottomJoinBorderConvex;
            copyBorderToImage(rightToBottomJoinBorderConvex, c);
          }
        }

        if (isConnectedToTop && isConnectedToLeft) {
          if (isBottomRightDifferent && isBottomDifferent && isRightDifferent) {
            drawnBorder = leftToTopJoinBorderConcave;
            copyBorderToImage(leftToTopJoinBorderConcave, c);
          }
          if (isTopLeftDifferent) {
            drawnBorder = leftToTopJoinBorderConvex;
            copyBorderToImage(leftToTopJoinBorderConvex, c);
          }
        }

        if (isConnectedToTop && isConnectedToRight) {
          if (isBottomLeftDifferent && isBottomDifferent && isLeftDifferent) {
            drawnBorder = rightToTopJoinBorderConcave;
            copyBorderToImage(rightToTopJoinBorderConcave, c);
          }
          if (isTopRightDifferent) {
            drawnBorder = rightToTopJoinBorderConvex;
            copyBorderToImage(rightToTopJoinBorderConvex, c);
          }
        }

        if (!!drawnBorder) {
          for (let dy = 0; dy < COMPOSITION_TO_PIXELS; ++dy) {
            for (let dx = 0; dx < COMPOSITION_TO_PIXELS; ++dx) {
              const index =
                x * COMPOSITION_TO_PIXELS +
                dx +
                (y * COMPOSITION_TO_PIXELS + dy) * imageState.w;
              if (
                drawnBorder[dx + dy * COMPOSITION_TO_PIXELS] !==
                TRANSPARENT_COLOR
              ) {
                imageState.drawn[index] = ImageDrawnState.DrawnRegionBorder;
              }
            }
          }
        }
      }
    }
  };
};

const generateBorders = (border: number[]) => {
  const borderWidth = border.length;
  const mirrorBorderVertical = mirrorVertical(borderWidth, borderWidth);
  const mirrorBorderHorizontal = mirrorHorizontal(borderWidth, borderWidth);

  const verticalBorder = new Int8Array(borderWidth * borderWidth);
  for (let x = 0; x < borderWidth; ++x) {
    for (let y = 0; y < borderWidth; ++y) {
      verticalBorder[y * borderWidth + x] = border[x];
    }
  }
  const horizontalBorder = new Int8Array(borderWidth * borderWidth);
  for (let y = 0; y < borderWidth; ++y) {
    for (let x = 0; x < borderWidth; ++x) {
      horizontalBorder[y * borderWidth + x] = border[y];
    }
  }
  const joinBorderConcave = new Int8Array(borderWidth * borderWidth);
  for (let y = 0; y < borderWidth; ++y) {
    for (let x = 0; x <= y; ++x) {
      joinBorderConcave[y * borderWidth + x] = border[y];
    }
  }
  for (let x = 0; x < borderWidth; ++x) {
    for (let y = 0; y <= x; ++y) {
      joinBorderConcave[y * borderWidth + x] = border[x];
    }
  }
  const joinBorderConvex = new Int8Array(borderWidth * borderWidth);
  for (let y = 0; y < borderWidth; ++y) {
    for (let x = 0; x <= y; ++x) {
      joinBorderConvex[y * borderWidth + x] = border[borderWidth - y - 1];
    }
  }
  for (let x = 0; x < borderWidth; ++x) {
    for (let y = 0; y <= x; ++y) {
      joinBorderConvex[y * borderWidth + x] = border[borderWidth - x - 1];
    }
  }
  return {
    verticalBorderFacingLeft: verticalBorder,
    verticalBorderFacingRight: mirrorBorderHorizontal(verticalBorder),
    horizontalBorderFacingTop: horizontalBorder,
    horizontalBorderFacingBottom: mirrorBorderVertical(horizontalBorder),
    leftToTopJoinBorderConvex: joinBorderConvex,
    leftToTopJoinBorderConcave: joinBorderConcave,
    leftToBottomJoinBorderConvex: mirrorBorderVertical(joinBorderConvex),
    leftToBottomJoinBorderConcave: mirrorBorderVertical(joinBorderConcave),
    rightToTopJoinBorderConvex: mirrorBorderHorizontal(joinBorderConvex),
    rightToTopJoinBorderConcave: mirrorBorderHorizontal(joinBorderConcave),
    rightToBottomJoinBorderConvex: mirrorBorderVertical(
      mirrorBorderHorizontal(joinBorderConvex),
    ),
    rightToBottomJoinBorderConcave: mirrorBorderVertical(
      mirrorBorderHorizontal(joinBorderConcave),
    ),
  };
};
