import { Dimension, TwoDimensionData } from '../../types';
import { getFlagsAsIndexes } from '../../utils/array';
import { getWFCSimpleTileModel } from '../model/simple-wfc';
import { initWFCRuntime, runWFCWasm } from '../runtime/wasm-wfc';

const WAVE_TO_OUTPUT_MULTIPLIER = 2;

export const WALL_PIXEL = 0;
export const WILD_CARD_PIXEL = 1;

export type SimpleWallsMetaPixel = [
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
]; // 3x3

export const SIMPLE_WALLS_META_PIXELS: SimpleWallsMetaPixel[] = [
  [
    WILD_CARD_PIXEL,
    WILD_CARD_PIXEL,
    WILD_CARD_PIXEL,
    WILD_CARD_PIXEL,
    WILD_CARD_PIXEL,
    WILD_CARD_PIXEL,
    WILD_CARD_PIXEL,
    WILD_CARD_PIXEL,
    WILD_CARD_PIXEL,
  ], // empty
  [
    WILD_CARD_PIXEL,
    WALL_PIXEL,
    WILD_CARD_PIXEL,
    WILD_CARD_PIXEL,
    WALL_PIXEL,
    WILD_CARD_PIXEL,
    WILD_CARD_PIXEL,
    WALL_PIXEL,
    WILD_CARD_PIXEL,
  ], // vertical wall
  [
    WILD_CARD_PIXEL,
    WILD_CARD_PIXEL,
    WILD_CARD_PIXEL,
    WALL_PIXEL,
    WALL_PIXEL,
    WALL_PIXEL,
    WILD_CARD_PIXEL,
    WILD_CARD_PIXEL,
    WILD_CARD_PIXEL,
  ], // horizontal wall
  [
    WILD_CARD_PIXEL,
    WALL_PIXEL,
    WILD_CARD_PIXEL,
    WALL_PIXEL,
    WALL_PIXEL,
    WILD_CARD_PIXEL,
    WILD_CARD_PIXEL,
    WALL_PIXEL,
    WILD_CARD_PIXEL,
  ], // vertical w/ left horizontal
  [
    WILD_CARD_PIXEL,
    WALL_PIXEL,
    WILD_CARD_PIXEL,
    WILD_CARD_PIXEL,
    WALL_PIXEL,
    WALL_PIXEL,
    WILD_CARD_PIXEL,
    WALL_PIXEL,
    WILD_CARD_PIXEL,
  ], // vertical w/ right horizontal
  [
    WILD_CARD_PIXEL,
    WILD_CARD_PIXEL,
    WILD_CARD_PIXEL,
    WALL_PIXEL,
    WALL_PIXEL,
    WALL_PIXEL,
    WILD_CARD_PIXEL,
    WALL_PIXEL,
    WILD_CARD_PIXEL,
  ], // horizontal w/ bottom vertical
  [
    WILD_CARD_PIXEL,
    WALL_PIXEL,
    WILD_CARD_PIXEL,
    WALL_PIXEL,
    WALL_PIXEL,
    WALL_PIXEL,
    WILD_CARD_PIXEL,
    WILD_CARD_PIXEL,
    WILD_CARD_PIXEL,
  ], // horizontal w/ top vertical
  [
    WILD_CARD_PIXEL,
    WALL_PIXEL,
    WILD_CARD_PIXEL,
    WALL_PIXEL,
    WALL_PIXEL,
    WILD_CARD_PIXEL,
    WILD_CARD_PIXEL,
    WILD_CARD_PIXEL,
    WILD_CARD_PIXEL,
  ], // top left corner
  [
    WILD_CARD_PIXEL,
    WALL_PIXEL,
    WILD_CARD_PIXEL,
    WILD_CARD_PIXEL,
    WALL_PIXEL,
    WALL_PIXEL,
    WILD_CARD_PIXEL,
    WILD_CARD_PIXEL,
    WILD_CARD_PIXEL,
  ], // top right corner
  [
    WILD_CARD_PIXEL,
    WILD_CARD_PIXEL,
    WILD_CARD_PIXEL,
    WALL_PIXEL,
    WALL_PIXEL,
    WILD_CARD_PIXEL,
    WILD_CARD_PIXEL,
    WALL_PIXEL,
    WILD_CARD_PIXEL,
  ], // bottom left corner
  [
    WILD_CARD_PIXEL,
    WILD_CARD_PIXEL,
    WILD_CARD_PIXEL,
    WILD_CARD_PIXEL,
    WALL_PIXEL,
    WALL_PIXEL,
    WILD_CARD_PIXEL,
    WALL_PIXEL,
    WILD_CARD_PIXEL,
  ], // bottom right corner
  [
    WILD_CARD_PIXEL,
    WALL_PIXEL,
    WILD_CARD_PIXEL,
    WALL_PIXEL,
    WALL_PIXEL,
    WALL_PIXEL,
    WILD_CARD_PIXEL,
    WALL_PIXEL,
    WILD_CARD_PIXEL,
  ], // cross
];

export const SIMPLE_WALLS_META_PIXEL_DIMENSION = 3;

export enum SimpleWallsMetaPixelType {
  EMPTY = 0,
  VERTICAL_WALL = 1,
  HORIZONTAL_WALL = 2,
  VERTICAL_WALL_WITH_LEFT_HORIZONTAL = 3,
  VERTICAL_WALL_WITH_RIGHT_HORIZONTAL = 4,
  HORIZONTAL_WALL_WITH_BOTTOM_VERTICAL = 5,
  HORIZONTAL_WALL_WITH_TOP_VERTICAL = 6,
  TOP_LEFT_CORNER = 7,
  TOP_RIGHT_CORNER = 8,
  BOTTOM_LEFT_CORNER = 9,
  BOTTOM_RIGHT_CORNER = 10,
  CROSS = 11,
}

const DEFAULT_WEIGHTS = {
  [SimpleWallsMetaPixelType.VERTICAL_WALL]: 4,
  [SimpleWallsMetaPixelType.HORIZONTAL_WALL]: 4,
  [SimpleWallsMetaPixelType.VERTICAL_WALL_WITH_LEFT_HORIZONTAL]: 1,
  [SimpleWallsMetaPixelType.VERTICAL_WALL_WITH_RIGHT_HORIZONTAL]: 1,
  [SimpleWallsMetaPixelType.HORIZONTAL_WALL_WITH_BOTTOM_VERTICAL]: 1,
  [SimpleWallsMetaPixelType.HORIZONTAL_WALL_WITH_TOP_VERTICAL]: 1,
  [SimpleWallsMetaPixelType.TOP_LEFT_CORNER]: 1,
  [SimpleWallsMetaPixelType.TOP_RIGHT_CORNER]: 1,
  [SimpleWallsMetaPixelType.BOTTOM_LEFT_CORNER]: 1,
  [SimpleWallsMetaPixelType.BOTTOM_RIGHT_CORNER]: 1,
  [SimpleWallsMetaPixelType.CROSS]: 1,
  [SimpleWallsMetaPixelType.EMPTY]: 2,
};

export const generateSimpleWalls = async (
  wasmCode: any,
  randomSource: number,
  waveDimension: Dimension,
  weights: {
    [metaPixelType in SimpleWallsMetaPixelType]?: number;
  } = DEFAULT_WEIGHTS,
  waveToOutputMultiplier: number = WAVE_TO_OUTPUT_MULTIPLIER,
): Promise<TwoDimensionData> => {
  const patternModel = getWFCSimpleTileModel<SimpleWallsMetaPixelType>({
    tiles: SIMPLE_WALLS_META_PIXELS,
    tileDimensions: SIMPLE_WALLS_META_PIXEL_DIMENSION,
    weights,
  });

  const wfcProps = {
    width: waveDimension.w,
    height: waveDimension.h,
    model: patternModel,
    randomSource,
    wasmCode,
  };
  const runtime = await initWFCRuntime(wfcProps);
  // const startWasmTime = performance.now();
  runWFCWasm(runtime);
  // const endWasmTime = performance.now();
  // console.log(`Simple Walls WFC took ${endWasmTime - startWasmTime} milliseconds.`);
  const wave = new Uint8Array(runtime.wfcWasmInstance.memory.buffer);
  const simpleWallsMetaPixelsDimensionInPattern =
    SIMPLE_WALLS_META_PIXEL_DIMENSION * waveToOutputMultiplier;
  const width = wfcProps.width * simpleWallsMetaPixelsDimensionInPattern + 2;
  const height = wfcProps.height * simpleWallsMetaPixelsDimensionInPattern + 2;
  const output = new Uint8Array(width * height);

  for (let x = 0; x < width; x++) {
    for (let y = 0; y < height; y++) {
      if (x === 0 || y === 0 || x === width - 1 || y === height - 1) {
        output[x + y * width] = WALL_PIXEL;
      } else {
        output[x + y * width] = WILD_CARD_PIXEL;
      }
    }
  }

  for (let i = 0; i < wfcProps.width * wfcProps.height; i++) {
    const tileIndexes = getFlagsAsIndexes(
      wave,
      patternModel.stateFlagsSize,
      i,
      runtime.memoryProps.pointerToWave,
    );
    if (tileIndexes.length === 1) {
      const tile = patternModel.tileAndHits[tileIndexes[0]][0];
      const x = i % wfcProps.width;
      const y = Math.floor(i / wfcProps.width);
      const patternX = x * simpleWallsMetaPixelsDimensionInPattern + 1;
      const patternY = y * simpleWallsMetaPixelsDimensionInPattern + 1;

      for (let ti = 0; ti < tile.length; ti++) {
        const metaPixel = tile[ti];
        const dx = ti % SIMPLE_WALLS_META_PIXEL_DIMENSION;
        const dy = Math.floor(ti / SIMPLE_WALLS_META_PIXEL_DIMENSION);
        const tileInPatternX = patternX + dx * waveToOutputMultiplier;
        const tileInPatternY = patternY + dy * waveToOutputMultiplier;
        for (let x = 0; x < waveToOutputMultiplier; x++) {
          for (let y = 0; y < waveToOutputMultiplier; y++) {
            output[tileInPatternX + x + (tileInPatternY + y) * width] =
              metaPixel;
          }
        }
      }
    }
  }

  return {
    d: output,
    w: width,
    h: height,
  };
};
