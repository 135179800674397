import { ColorGene } from './types';

export enum EncodedColorPropsType {
  Generator = 0,
  RandomSource = 1,
}

export type EncodedColorGene = ColorGene & {
  type: EncodedColorPropsType.Generator;
};

export type EncodedColorRandomSource = {
  type: EncodedColorPropsType.RandomSource;
  randomSource: number;
};

export type EncodedColorProps = EncodedColorGene | EncodedColorRandomSource;

export type EncodingMetadata<T> = [
  T,
  number,
  'normalized-float' | 'uint' | 'int' | 'float',
];

const get = <O extends object, V = any>(obj: O, path: string): V => {
  const keys = path.split('.');
  let value: any = obj;
  for (const key of keys) {
    const match = key.match(/^(\w+)(?:\[(\d+)\])?$/);
    if (match) {
      const [, propName, index] = match;
      value = value[propName as keyof O];
      if (index !== undefined) {
        value = value[parseInt(index, 10) as keyof O];
      }
    } else {
      value = value[key];
    }
    if (value === undefined) break;
  }
  return value as V;
};

const set = <O extends object, V = any>(obj: O, path: string, value: V) => {
  const keys = path.split('.');
  let current: any = obj;
  for (let i = 0; i < keys.length - 1; i++) {
    const key = keys[i];
    const match = key.match(/^(\w+)(?:\[(\d+)\])?$/);
    if (match) {
      const [, propName, index] = match;
      if (!(propName in current)) {
        current[propName] = index ? [] : {};
      }
      current = current[propName];
      if (index !== undefined) {
        const numIndex = parseInt(index, 10);
        if (current.length <= numIndex) {
          current.length = numIndex + 1;
        }
        current = current[numIndex];
      }
    } else {
      current[key] = current[key] || {};
      current = current[key];
    }
  }
  const lastKey = keys[keys.length - 1];
  const lastMatch = lastKey.match(/^(\w+)(?:\[(\d+)\])?$/);
  if (lastMatch) {
    const [, propName, index] = lastMatch;
    if (index !== undefined) {
      if (!(propName in current)) {
        current[propName] = [];
      }
      current[propName][parseInt(index, 10)] = value;
    } else {
      current[propName] = value;
    }
  } else {
    current[lastKey] = value;
  }
};

const ENCODING_METADATA = {
  [EncodedColorPropsType.RandomSource]: [
    ['type', 1, 'uint'],
    ['randomSource', 8, 'uint'],
  ],
  [EncodedColorPropsType.Generator]: [
    ['type', 1, 'uint'],

    ['lBackgroundRanges[0]', 1, 'normalized-float'],
    ['lBackgroundRanges[1]', 1, 'normalized-float'],
    ['lBackgroundRanges[2]', 1, 'normalized-float'],
    ['lBackgroundRanges[3]', 1, 'normalized-float'],

    ['lForegroundRanges[0]', 1, 'normalized-float'],
    ['lForegroundRanges[1]', 1, 'normalized-float'],
    ['lForegroundRanges[2]', 1, 'normalized-float'],
    ['lForegroundRanges[3]', 1, 'normalized-float'],

    ['sBackgroundRanges[0]', 1, 'normalized-float'],
    ['sBackgroundRanges[1]', 1, 'normalized-float'],
    ['sBackgroundRanges[2]', 1, 'normalized-float'],
    ['sBackgroundRanges[3]', 1, 'normalized-float'],
    ['sBackgroundRanges[4]', 1, 'normalized-float'],

    ['sForegroundRanges[0]', 1, 'normalized-float'],
    ['sForegroundRanges[1]', 1, 'normalized-float'],
    ['sForegroundRanges[2]', 1, 'normalized-float'],
    ['sForegroundRanges[3]', 1, 'normalized-float'],
    ['sForegroundRanges[4]', 1, 'normalized-float'],

    ['backgroundDegradationCoeff', 1, 'normalized-float'],

    ['hBackgroundStart', 2, 'uint'],

    ['hMiddlegroundCenterIndex', 1, 'uint'],

    ['hForegroundStartIndex', 1, 'uint'],
    ['hForegroundOffset', 2, 'int'],

    ['hBackgroundCycles', 2, 'float'],
    ['hForegroundCycles', 2, 'float'],
  ],
} satisfies Record<EncodedColorProps['type'], EncodingMetadata<string>[]>;

export const ENCODED_COLOR_SIZE_IN_BYTES = 32;

// const isEncodedColorRandomSource = (props: EncodedColorProps): props is EncodedColorRandomSource => {
//   return props.type === EncodedColorPropsType.RandomSource;
// }

export const encodeColorProps = (props: EncodedColorProps) => {
  let encodedGene = '0x';

  const encodingMetadata = ENCODING_METADATA[props.type];
  let binaryEncodedGene: string = '';
  for (const [key, size, typeUnsafe] of encodingMetadata) {
    const numBits = size / 0.125;
    const value = get(props, key);
    const isDefined = value !== undefined;
    if (!isDefined) {
      binaryEncodedGene += Number(0).toString(2).padStart(numBits, '0');
      continue;
    }
    const type = typeUnsafe as EncodingMetadata<string>[2];
    if (type === 'uint') {
      const maxSize = Math.pow(2, numBits) - 1;
      const clampedValue = Math.min(maxSize, Math.floor(value));
      binaryEncodedGene += clampedValue.toString(2).padStart(numBits, '0');
    } else if (type === 'int') {
      const maxAmplitude = Math.pow(2, numBits - 1);
      const clampedValue = Math.min(
        maxAmplitude - 1,
        Math.max(-maxAmplitude, Math.floor(value)),
      );
      binaryEncodedGene += (clampedValue + maxAmplitude)
        .toString(2)
        .padStart(numBits, '0');
    } else if (type === 'normalized-float') {
      const maxSize = Math.pow(2, numBits) - 1;
      const clampedValue = Math.min(1, Math.max(0, value));
      const binaryValue = Math.floor(clampedValue * maxSize).toString(2);
      binaryEncodedGene += binaryValue.padStart(numBits, '0');
    } else if (type === 'float') {
      // first x byte is used for the whole number part, the last byte is used for the decimal part
      const maxAmplitude = Math.pow(2, numBits - 8 - 1);
      const wholeNumber = Math.floor(value);
      const clampedWholeNumber = Math.min(
        maxAmplitude - 1,
        Math.max(-maxAmplitude, wholeNumber),
      );
      const decimal = value - wholeNumber;
      const wholeNumberBinary = (clampedWholeNumber + maxAmplitude)
        .toString(2)
        .padStart(numBits - 8, '0');
      binaryEncodedGene += wholeNumberBinary;
      const decimalBinary = Math.floor(decimal * 256)
        .toString(2)
        .padStart(8, '0');
      binaryEncodedGene += decimalBinary;
    }
  }
  binaryEncodedGene = binaryEncodedGene.padEnd(
    ENCODED_COLOR_SIZE_IN_BYTES * 8,
    '0',
  );
  for (let i = 0; i < ENCODED_COLOR_SIZE_IN_BYTES * 8; i += 4) {
    encodedGene += parseInt(binaryEncodedGene.slice(i, i + 4), 2).toString(16);
  }
  return encodedGene;
};

export const decodeColorProps = (encodedGene: string): EncodedColorProps => {
  let binaryEncodedGene: string = '';
  for (let i = 0; i < ENCODED_COLOR_SIZE_IN_BYTES * 2; i++) {
    binaryEncodedGene += parseInt(encodedGene[i + 2], 16)
      .toString(2)
      .padStart(4, '0');
  }
  const props: any = {};
  const type = parseInt(
    binaryEncodedGene.slice(0, 8),
    2,
  ) as EncodedColorPropsType;
  const encodingMetadata = ENCODING_METADATA[type];
  let index = 0;
  for (const [key, size, typeUnsafe] of encodingMetadata) {
    const numBits = size / 0.125;
    const binaryValue = binaryEncodedGene.slice(index, index + numBits);
    const type = typeUnsafe as EncodingMetadata<string>[2];
    if (type === 'normalized-float') {
      const maxSize = Math.pow(2, numBits) - 1;
      const value = parseInt(binaryValue, 2) / maxSize;
      set(props, key, value);
    } else if (type === 'int') {
      const maxAmplitude = Math.pow(2, numBits - 1);
      const value = parseInt(binaryValue, 2) - maxAmplitude;
      set(props, key, value);
    } else if (type === 'float') {
      const wholeNumber =
        parseInt(binaryValue.slice(0, numBits - 8), 2) -
        Math.pow(2, numBits - 8 - 1);
      const decimal = parseInt(binaryValue.slice(numBits - 8), 2) / 256;
      const value = wholeNumber + decimal;
      set(props, key, value);
    } else if (type === 'uint') {
      const value = parseInt(binaryValue, 2);
      set(props, key, value);
    }
    index += numBits;
  }
  return props as EncodedColorProps;
};
