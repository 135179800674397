import { Hsl } from '@pob/art-utils';
import { Lab, Lch, Rgb } from './types';

export const __scale8bit = (x: number, shift = 0) =>
  ((x < 0 ? 0 : x > 1 ? 1 : x) * 0xff + 0.5) << shift;

export const linearSrgb = (x: number) =>
  x <= 0.0031308 ? 12.92 * x : 1.055 * Math.pow(x, 1 / 2.4) - 0.055;

export const rgbSrgb = (rgb: Rgb): Rgb => [
  linearSrgb(rgb[0]),
  linearSrgb(rgb[1]),
  linearSrgb(rgb[2]),
];

export const srgbToScaledRgb = (rgb: Rgb): Rgb => {
  const r = __scale8bit(rgb[0]);
  const g = __scale8bit(rgb[1]);
  const b = __scale8bit(rgb[2]);
  return [r, g, b];
};

export const srgbCss = (rgb: Rgb) => {
  const r = __scale8bit(rgb[0]);
  const g = __scale8bit(rgb[1]);
  const b = __scale8bit(rgb[2]);
  return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
};

export const oklchOklab = (lch: Lch): Lab => {
  // Convert from polar form
  return [
    lch[0], // L is still L
    lch[1] * Math.cos((lch[2] * Math.PI) / 180), // a
    lch[1] * Math.sin((lch[2] * Math.PI) / 180), // b
  ];
};

export const oklabRgb = (lab: Lab): Rgb => {
  const l_ = lab[0] + 0.3963377774 * lab[1] + 0.2158037573 * lab[2];
  const m_ = lab[0] - 0.1055613458 * lab[1] - 0.0638541728 * lab[2];
  const s_ = lab[0] - 0.0894841775 * lab[1] - 1.291485548 * lab[2];

  const l = l_ * l_ * l_;
  const m = m_ * m_ * m_;
  const s = s_ * s_ * s_;

  return [
    +4.0767416621 * l - 3.3077115913 * m + 0.2309699292 * s,
    -1.2684380046 * l + 2.6097574011 * m - 0.3413193965 * s,
    -0.0041960863 * l - 0.7034186147 * m + 1.707614701 * s,
  ];
};

interface Cs {
  C_0: number;
  C_mid: number;
  C_max: number;
}

const pi = Math.PI;

const toe_inv = (l: number) => {
  if (l <= 0.08) {
    return l / 903.3;
  } else {
    return Math.pow((l + 0.16) / 1.16, 3);
  }
};

const get_Cs = (L: number, a_: number, b_: number): Cs => {
  const C_0 = 0.075 * L;
  const C_mid = 0.5 * L;
  const C_max = (0.9 * L) / Math.sqrt(a_ * a_ + b_ * b_);
  return { C_0, C_mid, C_max };
};

export const okhslOkLab = (hsl: Hsl): Lab => {
  const [h, s, l] = hsl;
  // if (l === 1.0) {
  //   console.log('l === 1.0', hsl);
  //   return [1.0, 1.0, 1.0];
  // } else if (l === 0.0) {
  //   return [0.0, 0.0, 0.0];
  // }

  const a_ = Math.cos(2.0 * pi * h);
  const b_ = Math.sin(2.0 * pi * h);
  const L = toe_inv(l);

  const cs = get_Cs(L, a_, b_);
  const C_0 = cs.C_0;
  const C_mid = cs.C_mid;
  const C_max = cs.C_max;

  const mid = 0.8;
  const mid_inv = 1.25;

  let C, t, k_0, k_1, k_2;

  if (s < mid) {
    t = mid_inv * s;

    k_1 = mid * C_0;
    k_2 = 1.0 - k_1 / C_mid;

    C = (t * k_1) / (1.0 - k_2 * t);
  } else {
    t = (s - mid) / (1.0 - mid);

    k_0 = C_mid;
    k_1 = ((1.0 - mid) * C_mid * C_mid * mid_inv * mid_inv) / C_0;
    k_2 = 1.0 - k_1 / (C_max - C_mid);

    C = k_0 + (t * k_1) / (1.0 - k_2 * t);
  }

  return [L, C * a_, C * b_];
  // const rgb = oklabRgb([L, C * a_, C * b_]);
  // return {
  //   r: srgb_transfer_function(rgb.r),
  //   g: srgb_transfer_function(rgb.g),
  //   b: srgb_transfer_function(rgb.b),
  // };
};
