export const COLOR_CENTER_INDEX = 10;
export const INSCRIBED_COLOR_DEPTH = 5;

export const COMPOSITION_TO_PIXELS = 4;
export const TRANSPARENT_COLOR = 127;
export const INSCRIBED_SYMBOL_COLOR = 1;

export enum ImageDrawnState {
  DrawnContinentBorder = 1,
  DrawnRegionAsNoise = 2,
  DrawnLanguageBorder = 3,
  DrawnRegionAsSymbols = 4,
  DrawnRegionAsCorners = 5,
  DrawnRegionBorder = 6,
  DrawnRegionAsLanguage = 7,
  DrawnRegionAsSymbolsGrid = 8,
  DrawnRegionAsSigil = 9,
  DrawnRegionAsSigilBorder = 10,
  DrawnRegionAsSigilAddress = 11,
  DrawnRegionAsSigilAddressCrest = 12,
  DrawnRegionAsSigilAddressBorder = 13,
  DrawnRegionAsSigilTxn = 14,
  DrawnRegionAsSigilTxnBorder = 15,
}
