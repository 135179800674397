import { OverlappingWFCModel } from '../algorithms/model/wfc';
import { WFCWasmRuntime } from '../algorithms/runtime/wasm-wfc';
import {
  WALL_PIXEL,
  WILD_CARD_PIXEL,
} from '../algorithms/variant/simple-walls';
import {
  ArtState,
  CompositionSymmetry,
  Dimension,
  TwoDimensionData,
} from '../types';
import { getArea } from '../utils/2d';
import { getFirstTileState } from './utils';

const OUTPUT_MULTIPLIER_BY_SYMMETRY = {
  [CompositionSymmetry.Vertical]: {
    w: 2,
    h: 1,
  },
  [CompositionSymmetry.Quadrant]: {
    w: 2,
    h: 2,
  },
  [CompositionSymmetry.Horizontal]: {
    w: 1,
    h: 2,
  },
} as const satisfies Record<CompositionSymmetry, Dimension>;

export const getOutputFromWave = (
  artState: ArtState,
  runtime: WFCWasmRuntime<OverlappingWFCModel>,
): TwoDimensionData => {
  const { symmetry: compositionSymmetry } = artState.composition;
  const { wfcWasmInstance, model } = runtime;
  const wave = new Uint8Array(
    wfcWasmInstance.memory.buffer,
    runtime.memoryProps.pointerToWave,
  );
  const getFirstTileStateForWave = getFirstTileState(
    model.stateFlagsSize,
    model.tileAndHits,
    wave,
  );
  // first populate a output array of tile states with symmetry applied
  const outputDimension = {
    w: runtime.width * OUTPUT_MULTIPLIER_BY_SYMMETRY[compositionSymmetry].w,
    h: runtime.height * OUTPUT_MULTIPLIER_BY_SYMMETRY[compositionSymmetry].h,
  };
  const output = new Uint8Array(getArea(outputDimension));
  for (let y = 0; y < runtime.height; y++) {
    for (let x = 0; x < runtime.width; x++) {
      const index = x + y * runtime.width;
      let tileState = getFirstTileStateForWave(index);
      // we handle funky borders at the edge by pruning them
      if (tileState === WALL_PIXEL) {
        if (y === 0) {
          if (
            getFirstTileStateForWave(index + runtime.width) === WILD_CARD_PIXEL
          ) {
            WALL_PIXEL;
            tileState = WILD_CARD_PIXEL;
          }
          if (
            getFirstTileStateForWave(index + runtime.width) === WALL_PIXEL &&
            getFirstTileStateForWave(index + runtime.width * 2) ===
              WILD_CARD_PIXEL
          ) {
            tileState = WILD_CARD_PIXEL;
          }
        }
        if (y === 1) {
          if (
            getFirstTileStateForWave(index - runtime.width) === WALL_PIXEL &&
            getFirstTileStateForWave(index + runtime.width) === WILD_CARD_PIXEL
          ) {
            tileState = WILD_CARD_PIXEL;
          }
        }
        if (x === 0) {
          if (getFirstTileStateForWave(index + 1) === WILD_CARD_PIXEL) {
            tileState = WILD_CARD_PIXEL;
          }
          if (
            getFirstTileStateForWave(index + 1) === WALL_PIXEL &&
            getFirstTileStateForWave(index + 2) === WILD_CARD_PIXEL
          ) {
            tileState = WILD_CARD_PIXEL;
          }
        }
        if (x === 1) {
          if (
            getFirstTileStateForWave(index - 1) === WALL_PIXEL &&
            getFirstTileStateForWave(index + 1) === WILD_CARD_PIXEL
          ) {
            tileState = WILD_CARD_PIXEL;
          }
        }
        // cuts down on fat borders occurring in the center
        if (y === runtime.height - 2) {
          if (
            getFirstTileStateForWave(index - runtime.width) ===
              WILD_CARD_PIXEL &&
            getFirstTileStateForWave(index + runtime.width) === WALL_PIXEL
          ) {
            tileState = WILD_CARD_PIXEL;
          }
        }
        // cuts down on fat borders occurring in the center
        if (x === runtime.width - 2) {
          if (
            getFirstTileStateForWave(index - 1) === WILD_CARD_PIXEL &&
            getFirstTileStateForWave(index + 1) === WALL_PIXEL
          ) {
            tileState = WILD_CARD_PIXEL;
          }
        }
        // removes the one off corner in the bottom right
        if (
          tileState !== WILD_CARD_PIXEL &&
          x === runtime.width - 2 &&
          y === runtime.height - 2
        ) {
          if (
            getFirstTileStateForWave(index + runtime.width) === WALL_PIXEL &&
            getFirstTileStateForWave(index + 1) === WALL_PIXEL &&
            getFirstTileStateForWave(index + runtime.width + 1) ===
              WALL_PIXEL &&
            getFirstTileStateForWave(index + runtime.width - 1) ===
              WALL_PIXEL &&
            getFirstTileStateForWave(index - runtime.width + 1) === WALL_PIXEL
          ) {
            tileState = WILD_CARD_PIXEL;
          }
        }
      }

      if (compositionSymmetry === CompositionSymmetry.Vertical) {
        output[x + y * outputDimension.w] = tileState;
        output[outputDimension.w - x - 1 + y * outputDimension.w] = tileState;
      } else if (compositionSymmetry === CompositionSymmetry.Horizontal) {
        output[x + y * outputDimension.w] = tileState;
        output[x + (outputDimension.h - y - 1) * outputDimension.w] = tileState;
      } else if (compositionSymmetry === CompositionSymmetry.Quadrant) {
        output[x + y * outputDimension.w] = tileState;
        output[outputDimension.w - x - 1 + y * outputDimension.w] = tileState;
        output[x + (outputDimension.h - y - 1) * outputDimension.w] = tileState;
        output[
          outputDimension.w -
            x -
            1 +
            (outputDimension.h - y - 1) * outputDimension.w
        ] = tileState;
      }
    }
  }
  return {
    ...outputDimension,
    d: output,
  };
};
