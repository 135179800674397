export const getOppositeDirection = (
  direction: CartesianDirection,
): CartesianDirection => {
  switch (direction) {
    case CartesianDirection.UP:
      return CartesianDirection.DOWN;
    case CartesianDirection.DOWN:
      return CartesianDirection.UP;
    case CartesianDirection.LEFT:
      return CartesianDirection.RIGHT;
    case CartesianDirection.RIGHT:
      return CartesianDirection.LEFT;
  }
};
export enum CartesianDirection {
  UP = 0,
  LEFT = 1,
  RIGHT = 2,
  DOWN = 3,
}

export const CARTESIAN_X_DIRECTION = [0, -1, 1, 0];
export const CARTESIAN_Y_DIRECTION = [-1, 0, 0, 1];

export const NUM_CARTESIAN_DIRECTIONS = CARTESIAN_X_DIRECTION.length;
